import { getOldChatMessages } from "./businessRules";
import { postChatUserActionsToKafkaTopic } from "./kafkaLogs";
import {
  CHAT_CHANNEL,
  EVENTS,
  MESSAGES,
  SESSION_END_INTERVAL,
} from "../constants";
import { clearSessionStore, extractDataFromIDC } from "./commonRules";

export const onOnline = async (
  convId,
  chatSDK,
  endChat,
  chatMessages,
  showChatWidget,
  sendingMessage,
  offlineTimeout,
  channelOptions,
  emailTranscript,
  sendMessageError,
  confirmCloseChat,
  inactivityTimeout,
  showPostChatSurvey,
  preChatSurveyResponses,
  type = "EXTERNAL"
) => {
  if (sessionStorage.getItem("timed-out") === "true") {
    showChatWidget.value = true;
    confirmCloseChat.value = true;
    if (CHAT_CHANNEL.INTERNAL === type) {
      if (localStorage.getItem("Authentication")) {
        const data = extractDataFromIDC();
        const email = data.state.user.email;
        emailTranscript.value = email ? email.toLowerCase().trim() : "";
      }
    } else {
      emailTranscript.value = preChatSurveyResponses.value.email
        ? preChatSurveyResponses.value.email.trim()
        : "";
    }
    showPostChatSurvey.value = false;
    endChat(false, true);
    clearSessionStore(type);
  } else if (chatSDK?.chatToken?.chatId) {
    const { messages } = await getOldChatMessages(
      chatSDK,
      preChatSurveyResponses.value,
      channelOptions.value,
      convId.value,
      type
    );
    chatMessages.value = messages;
    inactivityTimeout.value = "";
    sendingMessage.value = false;
    sendMessageError.value = "";
    clearTimeout(offlineTimeout.value);
    offlineTimeout.value = null;
    try {
      postChatUserActionsToKafkaTopic(
        EVENTS.REGAINED_CONNECTIVITY,
        CHAT_CHANNEL.ENTERPRISE,
        channelOptions.value,
        preChatSurveyResponses.value,
        convId.value
      );
    } catch (e) {
      console.log(e);
    }
  }
};

export const onOffline = (sendingMessage, sendMessageError, offlineTimeout) => {
  sendingMessage.value = true;
  sendMessageError.value = MESSAGES.NO_INTERNET;
  offlineTimeout.value = setTimeout(() => {
    sessionStorage.setItem("timed-out", "true");
  }, SESSION_END_INTERVAL);
};
