export const SUCCESS = "SUCCESS";
export const FAILURE = "FAILURE";
export const PRODUCTION = "production";
export const APP_NAME = "OMNICHANNEL_CHAT";
export const REFRESH_INTERVAL = 1000 * 60 * 2; // 2 minutes
export const SESSION_END_INTERVAL = 1000 * 60 * 3; // 3 minutes
export const CHATS_AVAILABLE_ON_PATHS = ["/d2d/selfinstall"];
export const CUSTOMER_SESSION_API_URL = import.meta.env
  .CUSTOMER_SESSION_API_URL;
export const INITIALIZATION_ERROR =
  "Conversation is closed. Please refresh the page to start new conversation";
export const OUTSIDE_OPERATING_HOURS =
  "Agents are not available at this time. Please try after some time";
export const departmentMapping = [
  {
    dept: "Sales",
    deptId: "146294496063627846",
  },
  {
    dept: "Customer Service",
    deptId: "146210741310792512",
  },
  {
    dept: "Technical Service",
    deptId: "146210740591606181",
  },
  {
    dept: "Collections",
    deptId: "4326258904527478102",
  },
];

export const UNAVAILABLE_REASON = {
  OOH: "OPERATING_HOURS",
  NO_AGENTS: "AGENT_UNAVAILABLE",
};

export const MESSAGES = {
  ERROR_INITIATE_CHAT: "Error while initializing chat. Please try again later.",
  END_CHAT_CONFIRMATION: "Are you sure you want to end the chat?",
  AGENTS_NOT_AVAILABLE:
    "Agents are not available at this time. Please try after some time.",
  OOH_MESSAGE:
    "You have reached us outside our operating hours. Please try again during our operating hours.",
  WAITING_FOR_AGENT: "Please wait for an agent to join the chat.",
  SEND_MESSAGE_FAILURE: "Unable to send message. Please try again later.",
  NO_INTERNET: "Lost connection to the internet.",
  INACTIVITY_TIMEOUT: "Session ended due to lost internet connection",
  CONVERSATION_CLOSED:
    "Conversation is closed. Please refresh the page to start a new conversation.",
  FAILED_TO_UPLOAD: "Failed to upload, please check the file and retry",
  PHONE_NOT_LINKED:
    "Your account does not have a linked phone number. You can link one by",
};

export const EVENTS = {
  START: "START",
  END: "END",
  ERROR: "ERROR",
  TRANSCRIPTS: "TRANSCRIPTS",
  SURVEY: "SURVEY",
  REFRESH: "REFRESH",
  REGAINED_CONNECTIVITY: "REGAINED_CONNECTIVITY",
};

export const CHAT_CHANNEL = {
  EXTERNAL: "EXTERNAL",
  INTERNAL: "INTERNAL",
  ENTERPRISE: "ENTERPRISE",
};

export const EXCEPTION_MESSAGE = {
  RESTORE_SESSION: "Unable to restore chat session after refresh",
  CONVERSATION_START:
    "Conversation in closed state, unable to reconnect after refresh or post data to chat.useractions on chat start",
  INITIALIZE_CHAT: "Error while initializing chat",
  SEND_MESSAGE: "Customer tried to send a message on closed conversation",
  CLOSE_CHAT_WINDOW: "Error while closing chat window",
  DOWNLOAD_ATTACHMENT: "Error while downloading attachment",
  UPLOAD_ATTACHMENT: "Error while uploading attachment",
  EMAIL_TRANSCRIPT: "Error while sending email transcript",
  CONVERSATION_INITIALIZATION: "Error while initializing conversation",
  WIDGET_OUTSIDE_OPERATING_HOURS: "Widget used outside operating hours",
  INITIATE_SURVEY: "Error while initiating survey",
  FAILED_TO_FETCH_MESSAGES: "Failed to fetch messages",
};
