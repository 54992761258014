<script setup>
import { isCustomerMessage } from "@microsoft/omnichannel-chat-sdk";

import Message from "./Message.vue";
import { isJsonMessage, printJsonMessage } from "../utils/commonRules";

const props = defineProps({
  chatMessages: Array,
  agentName: String,
  customerName: String,
  sendMessage: Function,
  downloadFile: Function,
  preChatSurveyResponses: Object,
  inputDisabledByAdaptiveCard: Boolean,
});

const emit = defineEmits([
  "update:messageOnClick",
  "update:inputDisabledByAdaptiveCard",
]);

const parseCitations = (msg) => {
  return JSON.parse(
    msg.substring("#NDS_WIDGET_ACTION=CITATIONS(".length, msg.length - 1)
  );
};

</script>

<template>
  <div
    id="scrollContainer"
    class="!zf-flex !zf-flex-col-reverse !zf-h-[90%] !zf-px-4 !zf-py-2 !zf-overflow-scroll zf-no-scrollbar"
  >
    <div
      v-for="(message, idx) in chatMessages"
      :key="message.id"
      class="!zf-flex !zf-flex-col !zf-gap-1"
    >
      <template v-if="!message.content.startsWith('#NDS_WIDGET_ACTION=')">
        <!-- BOT MSG -->
        <div
          class="!zf-flex !zf-flex-col !zf-items-start !zf-flex-wrap"
          v-if="!isCustomerMessage(message)"
        >
          <div
            class="!zf-text-ziply-blue !zf-flex !zf-justify-center !zf-items-center"
          >
            <p class="!zf-text-sm !zf-text-ziply-blue !zf-font-normal">
              {{ agentName || message.sender.displayName }}
            </p>
          </div>
          <Message
            :message="message"
            :inputDisabledByAdaptiveCard="inputDisabledByAdaptiveCard"
            @update:inputDisabledByAdaptiveCard="
              (newValue) => emit('update:inputDisabledByAdaptiveCard', newValue)
            "
            :next-message="idx > 0 ? chatMessages[idx - 1] : null"
            v-if="message.content !== ''"
            @update:message-on-click="
              (newValue, metaData) => sendMessage(newValue, metaData)
            "
          />
          <div
            class="!zf-bg-gray-100 !zf-rounded-lg !zf-px-4 !zf-py-2"
            v-if="message.fileMetadata !== undefined"
          >
            <button
              class="!zf-text-blue-600 !zf-text-sm zf-underline"
              @click="downloadFile(Object.freeze(message.fileMetadata))"
            >
              {{ message.fileMetadata.name }}
            </button>
          </div>
          <div v-if="message.timestamp !== ''" class="zf-mb-1">
            <p class="zf-italic !zf-text-[10px] zf-text-gray-500">
              {{ new Date(message.timestamp).toLocaleString() }}
            </p>
          </div>
        </div>
        <!-- Customer Message -->
        <div class="!zf-flex !zf-flex-col !zf-items-end" v-else>
          <div
            class="!zf-text-slate-800 !zf-rounded-full !zf-flex !zf-justify-center !zf-items-center"
          >
            <p class="!zf-text-sm !zf-text-ziply-blue !zf-font-normal">
              {{ customerName || preChatSurveyResponses.name }}
            </p>
          </div>
          <div
            class="!zf-bg-gray-200 !zf-rounded-lg !zf-p-2"
            v-if="message.content !== '' && typeof message.content !== 'object'"
          >
            <div
              class="!zf-text-gray-800 !zf-text-sm !zf-text-right zf-break-words"
            >
              <span
                v-if="isJsonMessage(message.content)"
                v-html="printJsonMessage(message.content)"
              >
              </span>
              <template
                v-else-if="
                  message.content !== '' &&
                  message.content.includes('Agent name')
                "
              >
                <div
                  class="!zf-text-gray-800 !zf-text-sm !zf-w-full !zf-text-right"
                >
                  <div
                    v-for="(value, key) in JSON.parse(message.content)"
                    :key="key"
                    class="!zf-w-full"
                  >
                    <table class="zf-w-full zf-border zf-rounded-lg">
                      <tr class="zf-w-full zf-border zf-rounded-lg">
                        <th class="zf-w-2/4 zf-text-center zf-p-1">
                          {{ key }}
                        </th>
                        <td class="zf-w-2/4 zf-text-center zf-p-1">
                          {{ value }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </template>
              <div v-else>{{ message.content }}</div>
            </div>
          </div>
          <div
            class="!zf-bg-gray-100 !zf-rounded-lg !zf-px-4 !zf-py-2"
            v-if="message.fileMetadata !== undefined"
          >
            <button
              class="!zf-text-blue-600 !zf-text-sm !zf-underline"
              @click="downloadFile(Object.freeze(message.fileMetadata))"
            >
              {{ message.fileMetadata.name }}
            </button>
          </div>
          <div v-if="message.timestamp !== ''" class="zf-mb-1">
            <p class="zf-italic !zf-text-[10px] zf-text-gray-500">
              {{ new Date(message.timestamp).toLocaleString() }}
            </p>
          </div>
        </div>
      </template>
      <template
        v-else-if="message.content.startsWith('#NDS_WIDGET_ACTION=CITATIONS(')"
        class="mb-2"
      >
        <div class="!zf-text-ziply-blue !zf-flex">
          <div class="!zf-flex !zf-flex-col !zf-mt-2 !zf-items-start">
            <ul
              class="!zf-bg-gray-100 zf-rounded-lg !zf-px-4 !zf-py-2 zf-text-left zf-list-none"
            >
              <li
                v-for="citation in parseCitations(message.content)"
                class="rounded border px-2 py-1"
              >
                <a target="_blank" :href="citation.Url"
                  >{{ citation.Id }}. {{ citation.Name }}</a
                >
              </li>
            </ul>
            <div v-if="message.timestamp !== ''" class="zf-mb-1">
              <p class="zf-italic zf-text-[10px] zf-text-gray-500">
                {{ new Date(message.timestamp).toLocaleString() }}
              </p>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
