<script setup>
import { ref, onMounted } from "vue";

import OmniChatExternal from "../OmniChatExternal.vue";
import OmniChatExternalBot from "../OmniChatExternalBot.vue";

const showChatBot = ref(null);
const thirtyMinutes = 30 * 60 * 1000;
const checkChatBotStatusUrl = import.meta.env.VITE_CHATBOT_ENABLER_CHECK;

const checkChatBotStatus = async () => {
  const currentTime = new Date();
  const storedTime = localStorage.getItem("chatBotTimestamp");
  const chatBotEnabled = localStorage.getItem("chatBotEnabled");

  if (
    chatBotEnabled === "true" &&
    storedTime &&
    currentTime - new Date(storedTime) < thirtyMinutes
  ) {
    showChatBot.value = true;
    return;
  } else {
    localStorage.removeItem("chatBotEnabled");
    localStorage.removeItem("chatBotTimestamp");
  }

  try {
    const response = await fetch(checkChatBotStatusUrl, {
      method: "POST",
      body: JSON.stringify({
        env: import.meta.env.VITE_ENV,
        referer: window.top.location.href,
      }),
    }).then((response) => {
      return response.json();
    });
    if (response.is_chatbot_enabled === true) {
      showChatBot.value = true;
      localStorage.setItem("chatBotEnabled", "true");
      localStorage.setItem("chatBotTimestamp", currentTime);
    } else {
      showChatBot.value = false;
      localStorage.setItem("chatBotEnabled", "false");
    }
  } catch (error) {
    showChatBot.value = false;
    console.error("Error making API call", error);
  }
};

onMounted(() => {
  checkChatBotStatus();
});
</script>

<template>
  <div v-if="showChatBot === true">
    <OmniChatExternalBot :showChatBot="showChatBot" />
  </div>
  <div v-if="showChatBot === false">
    <OmniChatExternal :showChatBot="showChatBot" />
  </div>
</template>
