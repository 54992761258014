import { createApp } from "vue";

import "./styles/style.css";
import OmniChatInternal from "./OmniChatInternal.vue";
import OmniChatEnterprise from "./OmniChatEnterprise.vue";
// import ChatbotEnabler from "./components/ChatbotEnabler.vue";
import OmniChatExternalBot from "./OmniChatExternalBot.vue";

const external = document.getElementById("MS365ChatWidget");
const internal = document.getElementById("MS365ChatWidgetInternal");
const enterprise = document.getElementById("MS365ChatWidgetEnterprise");

if (external) {
  const externalChat =  createApp(OmniChatExternalBot);
  externalChat.mount("#MS365ChatWidget");
} else if (internal) {
  const internalChat = createApp(OmniChatInternal);
  internalChat.mount("#MS365ChatWidgetInternal");
} else if (enterprise) {
  const enterpriseChat = createApp(OmniChatEnterprise);
  enterpriseChat.mount("#MS365ChatWidgetEnterprise");
}
