import { v4 as uuidv4 } from "uuid";
import moment from "moment-timezone";
import { UAParser } from "ua-parser-js";
import { APP_NAME, CHAT_CHANNEL, EVENTS, FAILURE, SUCCESS } from "../constants";

const detectBrowserAndDevice = () => {
  const userAgent = window.navigator.userAgent;
  const isMobile = /Mobi/i.test(userAgent);
  const screenWidth = window.screen.width;
  let device = "";
  let browser = "";
  // Detect Device
  if (isMobile) {
    device = "Mobile";
  } else if (screenWidth < 768) {
    device = "Tablet";
  } else {
    device = "Desktop/Laptop";
  }
  // Detect Browser
  const parser = new UAParser();
  const userAgentInfo = parser.getResult();
  browser = userAgentInfo.browser.name;
  return { browser, device };
};

const splitName = (name) => {
  const names = name.split(" ");
  const firstName = names[0] || "";
  const lastName = names.slice(1).join(" ") || "";
  return { firstName, lastName };
};

const getTopicByEvent = (event) => {
  let topic = "";
  let eventAction = "";
  let eventStatus = "";
  switch (event) {
    case EVENTS.START:
      topic = "chat.useractions";
      eventAction = "START_CHAT";
      eventStatus = SUCCESS;
      break;
    case EVENTS.END:
      topic = "chat.useractions";
      eventAction = "END_CHAT";
      eventStatus = SUCCESS;
      break;
    case EVENTS.REFRESH:
      topic = "chat.useractions";
      eventAction = "REFRESH";
      eventStatus = SUCCESS;
      break;
    case EVENTS.ERROR:
      topic = "chat.exceptions";
      eventAction = "CHAT_ERROR";
      eventStatus = FAILURE;
      break;
    case EVENTS.TRANSCRIPTS:
      topic = "chat.useractions";
      eventAction = "CHAT_TRANSCRIPTS";
      eventStatus = SUCCESS;
      break;
    case EVENTS.SURVEY:
      topic = "chat.useractions";
      eventAction = "CHAT_SURVEY";
      eventStatus = SUCCESS;
      break;
    case EVENTS.REGAINED_CONNECTIVITY:
      topic = "chat.useractions";
      eventAction = "REGAINED_CONNECTIVITY";
      eventStatus = SUCCESS;
      break;
    default:
      topic = "chat.useractions";
      eventAction = "UNKNOWN";
      eventStatus = FAILURE;
      break;
  }
  return { topic, eventAction, eventStatus };
};

function extractFieldAgentInfo(preChatResponse) {
  const preChatAgentName = preChatResponse["Agent name, CRIS/CORP ID"];
  const phone = preChatResponse["Phone number"];
  const email = preChatResponse["Email"];
  const nameParts = preChatAgentName ? preChatAgentName.split(", ") : "";
  const firstName = nameParts ? nameParts[0].split(" ")[0] : "";
  const lastName = nameParts ? nameParts[0].split(" ")[1] : "";
  const corpID = nameParts ? nameParts[1] : "";
  return {
    firstName,
    lastName,
    phone,
    email,
    corpID,
  };
}

export const postChatUserActionsToKafkaTopic = async (
  event,
  chatType,
  channelOptions = {},
  preChatSurveyResponses,
  conversationId,
  conversationDetails = { message: "Default value" },
  allMessages = [],
  triggeredBy = "NON_AGENT"
) => {
  let uuid = uuidv4();
  try {
    const { browser, device } = detectBrowserAndDevice();
    const url = import.meta.env.VITE_KAFKA_CUSTOMER_EVENTS;
    const { queues, utc_now } = channelOptions;
    const source = window.location.href;
    const currentChannel = preChatSurveyResponses.channel_id
      ? preChatSurveyResponses.channel_id
      : "";
    const metaData = JSON.stringify(
      chatType === "ENTERPRISE" || chatType === "INTERNAL"
        ? { current_channel: channelOptions }
        : { queues_response: queues, server_utc_time: utc_now }
    );
    const { firstName, lastName, phone, email, corpID } =
      preChatSurveyResponses.name
        ? splitName(preChatSurveyResponses.name)
        : Object.keys(preChatSurveyResponses).length === 0
        ? {}
        : extractFieldAgentInfo(preChatSurveyResponses);
    const { topic, eventAction, eventStatus } = getTopicByEvent(event);
    if (chatType === CHAT_CHANNEL.EXTERNAL || chatType === "ENTERPRISE") {
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          source: "Dynamics Chat",
        },
        body: JSON.stringify({
          topic: topic,
          messages: [
            {
              key: uuid,
              value: {
                key: uuid,
                conversationDetails,
                current_channel: currentChannel,
                customer_usi: preChatSurveyResponses.usi,
                customer_phone: preChatSurveyResponses.phone,
                customer_email: preChatSurveyResponses.email,
                customer_first_name: firstName,
                customer_last_name: lastName,
                event_triggered_from: APP_NAME,
                event_triggered_by: APP_NAME,
                event_trigger_type: triggeredBy,
                event_created_at: moment().toISOString(),
                event_final_status: eventStatus,
                event_action: eventAction,
                chat_type: chatType,
                conversation_id: conversationId,
                platform: device,
                browserUsed: browser,
                conversationData: allMessages,
                originSource: source,
                meta_data: metaData,
              },
            },
          ],
        }),
      });
    } else if (chatType === CHAT_CHANNEL.INTERNAL) {
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          source: "Dynamics Chat",
        },
        body: JSON.stringify({
          topic: "chat.fieldagentactions",
          messages: [
            {
              key: uuid,
              value: {
                key: uuid,
                agent_first_name: firstName,
                agent_last_name: lastName,
                agent_phone: phone,
                agent_email: email,
                agent_corpID: corpID,
                event_triggered_from: APP_NAME,
                event_triggered_by: APP_NAME,
                event_trigger_type: triggeredBy,
                event_created_at: moment().toISOString(),
                event_final_status: eventStatus,
                event_action: eventAction,
                chat_type: chatType,
                conversation_id: conversationId,
                conversationDetails,
                conversationData: allMessages,
                platform: device,
                browserUsed: browser,
                originSource: source,
                meta_data: metaData,
              },
            },
          ],
        }),
      });
    }
  } catch (e) {
    console.log(e);
  }
};

export const postChatExceptionsToKafkaTopic = async (
  event,
  chatType,
  preChatSurveyResponses = {},
  channelOptions = {},
  exception,
  conversationId,
  message,
  conversationDetails = { message: "Default value" }
) => {
  const uuid = uuidv4();
  try {
    const { browser, device } = detectBrowserAndDevice();
    const url = import.meta.env.VITE_KAFKA_CUSTOMER_EVENTS;
    const { queues, utc_now } = channelOptions;
    const source = window.location.href;
    const { firstName, lastName, phone, email, corpID } =
      preChatSurveyResponses.name
        ? splitName(preChatSurveyResponses.name)
        : Object.keys(preChatSurveyResponses).length === 0
        ? {}
        : extractFieldAgentInfo(preChatSurveyResponses);
    const { topic, eventAction, eventStatus } = getTopicByEvent(event);
    const metaData = JSON.stringify(
      chatType === "ENTERPRISE" || chatType === "INTERNAL"
        ? { current_channel: channelOptions }
        : { queues_response: queues, server_utc_time: utc_now }
    );
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        source: "Dynamics Chat",
      },
      body: JSON.stringify({
        topic: topic,
        messages: [
          {
            key: uuid,
            value: {
              key: uuid,
              [chatType === "INTERNAL"
                ? "agent_first_name"
                : "customer_first_name"]: firstName,
              [chatType === "INTERNAL"
                ? "agent_last_name"
                : "customer_last_name"]: lastName,
              [chatType === "INTERNAL" ? "agent_phone" : "customer_phone"]:
                phone,
              [chatType === "INTERNAL" ? "agent_email" : "customer_email"]:
                email,
              [chatType === "INTERNAL" ? "agent_corpID" : "customer_corpID"]:
                corpID,
              [chatType === "INTERNAL" ? "agent_usi" : "customer_usi"]:
                preChatSurveyResponses.usi,
              event_triggered_from: APP_NAME,
              event_triggered_by: APP_NAME,
              event_trigger_type: "NON_AGENT",
              event_created_at: moment().toISOString(),
              event_final_status: eventStatus,
              event_action: eventAction,
              chat_type: chatType,
              conversation_id: conversationId,
              platform: device,
              browserUsed: browser,
              exception_message: exception.message,
              exception_stack: exception.stack,
              failureReason: message,
              conversationDetails,
              originSource: source,
              meta_data: metaData,
            },
          },
        ],
      }),
    });
  } catch (e) {
    console.log(e);
  }
};
