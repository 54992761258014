<script setup>
import { defineProps, ref, defineEmits } from "vue";
import { PaperClipIcon } from "@heroicons/vue/24/solid";

const props = defineProps({
  isTyping: Boolean,
  systemMessage: String,
  messageToSend: String,
  sendMessage: Function,
  chatBotTyping: Boolean,
  processUpload: Function,
  sendingMessage: Boolean,
  sendMessageError: String,
  disableChatOnAdaptiveCard: { type: Boolean, default: false },
});

const limit = ref(2000);

defineEmits(["update:messageToSend"]);

const fileUpload = ref(null);
</script>

<template>
  <div class="!zf-w-full !zf-pb-2 !zf-px-4">
    <div v-if="isTyping || chatBotTyping" class="typing-event">
      <div class="agent-typing"></div>
    </div>
    <p
      v-if="sendMessageError !== ''"
      class="zf-text-red-500 zf-text-center zf-text-sm zf-pb-1"
    >
      {{ sendMessageError }}
    </p>
    <div class="!zf-flex !zf-gap-2 !zf-items-center !zf-justify-between">
      <input
        type="file"
        @change="processUpload($event)"
        class="zf-hidden"
        ref="fileUpload"
      />
      <button
        @click="fileUpload.click()"
        class="zf-bg-transparent"
        id="fileAttachment"
        :disabled="sendingMessage || chatBotTyping || disableChatOnAdaptiveCard"
      >
        <PaperClipIcon class="zf-h-5 zf-w-5" />
      </button>
      <div class="zf-w-full !zf-relative">
        <!-- Could not use v-model as we are passing the messageToSend as prop -->
        <input
          id="chatInputBox"
          type="text"
          maxlength="2000"
          rows="1"
          placeholder="Type your message here..."
          class="!zf-w-full !zf-rounded-lg !zf-bg-gray-100 !zf-px-2 !zf-py-2 !zf-relative !zf-resize-none zf-scrollbar-none zf-overflow-auto"
          :value="messageToSend"
          @input="$emit('update:messageToSend', $event.target.value)"
          @keyup.enter="sendMessage()"
          :disabled="
            sendingMessage || chatBotTyping || disableChatOnAdaptiveCard
          "
          :class="{
            'zf-bg-gray-100 animate-pulse': sendingMessage,
            '!zf-pr-8 ': limit - messageToSend.length < 100,
            '!zf-pr-10 ':
              limit - messageToSend.length >= 100 &&
              limit - messageToSend.length < 200,
          }"
          autocomplete="off"
        />
        <p
          v-if="limit - messageToSend.length <= 200"
          class="zf-absolute !zf-bottom-2 !zf-right-2 !zf-text-[8px] zf-text-red-500 zf-bg-white zf-rounded zf-p-0.5"
        >
          {{ limit - messageToSend.length }}
        </p>
      </div>
      <button
        id="sendButton"
        class="!zf-bg-ziply-blue !zf-rounded-lg !zf-px-4 !zf-py-2 !zf-text-gray-100 hover:zf-bg-ziply-blue-dark !zf-text-sm !zf-fill-white"
        :class="{
          '!zf-bg-gray-200 !zf-fill-ziply-blue': messageToSend.length === 0,
        }"
        :disabled="sendingMessage || chatBotTyping || disableChatOnAdaptiveCard"
        @click="sendMessage()"
      >
        SEND
      </button>
    </div>
  </div>
</template>
