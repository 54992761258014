import { getConversationData, getOldChatMessages } from "./businessRules";
import { CHAT_CHANNEL, EVENTS, EXCEPTION_MESSAGE } from "../constants";
import {
  postChatExceptionsToKafkaTopic,
  postChatUserActionsToKafkaTopic,
} from "./kafkaLogs";
import { clearSessionStore, scrollToBottom } from "./commonRules";

export const ifExistingSession = async (
  type,
  convId,
  endChat,
  chatSDK,
  isLoading,
  surveyUrl,
  closedExtra,
  chatMessages,
  showChatWidget,
  sendingMessage,
  channelOptions,
  emailTranscript,
  confirmCloseChat,
  showPostChatSurvey,
  initializeChatEvents,
  preChatSurveyResponses,
  chatBotTyping,
  inputDisabledByAdaptiveCard,
  savedMessages,
  showChatBot
) => {
  const checkPreviousSession =
    type === CHAT_CHANNEL.EXTERNAL
      ? sessionStorage.getItem("MS365ChatSession")
      : sessionStorage.getItem("MS365EnterpriseChatSession");
  if (checkPreviousSession) {
    try {
      const optionalParams = {};
      optionalParams.liveChatContext = JSON.parse(checkPreviousSession);
      await chatSDK.startChat(optionalParams);
      const { messages } = await getOldChatMessages(
        chatSDK,
        preChatSurveyResponses.value,
        channelOptions.value,
        convId.value,
        type
      );
      if (type === CHAT_CHANNEL.EXTERNAL && showChatBot === true) {
        savedMessages.value = messages;
      }
      chatMessages.value = messages;
      chatMessages.value.filter((msg) =>
        msg.content.startsWith("#NDS_WIDGET_ACTION=CITATIONS")
          ? true
          : msg.content.startsWith("#NDS_WIDGET_ACTION")
          ? false
          : true
      );
      if (
        type === CHAT_CHANNEL.EXTERNAL &&
        savedMessages.value.length > 0 &&
        savedMessages.value[0].content === "#NDS_WIDGET_ACTION=loading()"
      ) {
        if (inputDisabledByAdaptiveCard && !inputDisabledByAdaptiveCard.value) {
          if (chatBotTyping) {
            chatBotTyping.value = true;
          } else {
            chatBotTyping.value = false;
          }
        }
      }
      await initializeChatEvents();
      showChatWidget.value = true;
      closedExtra.value = true;
      preChatSurveyResponses.value =
        type === CHAT_CHANNEL.EXTERNAL
          ? JSON.parse(sessionStorage.getItem("MS365ChatPreChatSurvey"))
          : JSON.parse(
              sessionStorage.getItem("MS365EnterpriseChatPreChatSurvey")
            );
      const { conversationDetails } = await getConversationData(
        chatSDK,
        chatMessages
      );
      convId.value = conversationDetails.conversationId;
      if (sessionStorage.getItem("timed-out") === "true") {
        showPostChatSurvey.value = false;
        showChatWidget.value = true;
        confirmCloseChat.value = true;
        emailTranscript.value = preChatSurveyResponses.value.email
          ? preChatSurveyResponses.value.email.trim()
          : "";
        endChat(false, true);
      }
      if (sessionStorage.getItem("agent-ended-session") === true) {
        onAgentEndSession(
          type,
          convId,
          chatSDK,
          surveyUrl,
          chatMessages,
          sendingMessage,
          channelOptions,
          confirmCloseChat,
          showPostChatSurvey,
          preChatSurveyResponses
        );
      }
      postChatUserActionsToKafkaTopic(
        EVENTS.REFRESH,
        type,
        channelOptions.value,
        preChatSurveyResponses.value,
        convId.value,
        conversationDetails
      );
      scrollToBottom();
      isLoading.value = false;
      return true;
    } catch (e) {
      try {
        postChatExceptionsToKafkaTopic(
          EVENTS.ERROR,
          type,
          preChatSurveyResponses.value,
          channelOptions.value,
          e,
          convId.value,
          EXCEPTION_MESSAGE.RESTORE_SESSION
        );
      } catch (e) {
        console.log(e);
      }
      clearSessionStore(type);
    }
    isLoading.value = false;
    return false;
  } else {
    return false;
  }
};
