<script setup>
import { defineProps, ref } from "vue";

import { getIdOfQuestionByName } from "../utils/commonRules";

const props = defineProps({
  isLoading: Boolean,
  startChat: Function,
  isInternal: Boolean,
  channelOptions: Object,
  preChatQuestions: Object,
  preChatSurveyResponses: Object,
});

const options = ref([]);
const salesOption = ref("");
const name_input_error = ref("");
const email_input_error = ref("");
const phone_input_error = ref("");
const subject_input_error = ref("");
const company_name_input_error = ref("");
const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
try {
  props.preChatQuestions?.body?.forEach((element) => {
    if (
      element.type === "Input.ChoiceSet" &&
      element?.value?.includes("Billing")
    ) {
      options.value = element.choices;
      salesOption.value = element.choices.find(
        (item) => item.title === "Sales"
      )?.value;
      props.preChatSurveyResponses.subject = salesOption.value;
    }
  });
} catch (err) {
  console.log(err);
}

const currentQueue = props?.channelOptions ?? {};

const validateForm = () => {
  if (props.preChatSurveyResponses.name.length === 0) {
    name_input_error.value = "Please enter your name.";
    return;
  } else if (
    props.preChatSurveyResponses.name.length !== 0 &&
    props.preChatSurveyResponses.name.length < 3
  ) {
    name_input_error.value = "Please enter a valid name.";
    return;
  }
  if (props.preChatSurveyResponses.email.length === 0) {
    email_input_error.value = "Please enter your email.";
    return;
  } else if (
    props.preChatSurveyResponses.email.length !== 0 &&
    !emailPattern.test(props.preChatSurveyResponses.email)
  ) {
    email_input_error.value = "Please enter a valid email.";
    return;
  }
  if (props.preChatSurveyResponses.phone.length === 0) {
    phone_input_error.value = "Please enter your phone number.";
    return;
  } else if (props.preChatSurveyResponses.phone.length !== 0) {
    if (
      props.preChatSurveyResponses.phone.length > 0 &&
      props.preChatSurveyResponses.phone.length !== 14
    ) {
      phone_input_error.value = "Please enter a valid phone number.";
      return;
    }
  }
  if (props.preChatSurveyResponses.companyName.length === 0) {
    company_name_input_error.value = "Please enter your name.";
    return;
  } else if (
    props.preChatSurveyResponses.companyName.length !== 0 &&
    props.preChatSurveyResponses.companyName.length < 3
  ) {
    company_name_input_error.value = "Please enter a valid name.";
    return;
  }
  if (!props.preChatSurveyResponses.subject) {
    subject_input_error.value = "Please choose your subject.";
    return;
  }
  name_input_error.value = "";
  email_input_error.value = "";
  phone_input_error.value = "";
  company_name_input_error.value = "";
  subject_input_error.value = "";
  const { preChatResponse, chatContext } = buildPreChatResponse();
  props.startChat(preChatResponse, chatContext);
};

const buildPreChatResponse = () => {
  const nameKey = getIdOfQuestionByName(props.preChatQuestions, "Name");
  const emailKey = getIdOfQuestionByName(props.preChatQuestions, "Email");
  const phoneKey = getIdOfQuestionByName(props.preChatQuestions, "Phone");
  const companyNameKey = getIdOfQuestionByName(
    props.preChatQuestions,
    "CompanyName"
  );
  const subjectKey = getIdOfQuestionByName(props.preChatQuestions, "Subject");
  const preChatResponse = {
    Type: "InputSubmit",
  };
  const chatContext = {
    Source: { value: window.location.href || "Unknown", isDisplayable: true },
  };
  preChatResponse[nameKey] = props.preChatSurveyResponses.name;
  preChatResponse[emailKey] = props.preChatSurveyResponses.email;
  preChatResponse[phoneKey] = props.preChatSurveyResponses.phone.replace(
    /[^0-9]/g,
    ""
  );
  preChatResponse[subjectKey] = props.preChatSurveyResponses.subject;
  preChatResponse[companyNameKey] = props.preChatSurveyResponses.companyName;
  return { preChatResponse, chatContext };
};

const fillingPhone = () => {
  let value = props.preChatSurveyResponses.phone;
  value = value.replace(/\D/g, "");
  if (value.length <= 3) {
    props.preChatSurveyResponses.phone = value;
    phone_input_error.value = "";
  } else if (value.length <= 6) {
    props.preChatSurveyResponses.phone =
      "(" + value.substring(0, 3) + ") " + value.substring(3);
  } else {
    props.preChatSurveyResponses.phone =
      "(" +
      value.substring(0, 3) +
      ") " +
      value.substring(3, 6) +
      "-" +
      value.substring(6, 10);
  }
};

function validateName(event) {
  var charCode = event.keyCode;
  if (
    (charCode >= 97 && charCode <= 122) ||
    (charCode >= 65 && charCode <= 90) ||
    charCode === 32
  ) {
    return true;
  } else {
    event.preventDefault();
    return false;
  }
}
</script>

<template>
  <form method="post" @submit.prevent="validateForm()" v-if="!isInternal">
    <select v-model.trim="preChatSurveyResponses.subject" :class="{
      '!zf-border !zf-border-red-700 zf-mb-0': subject_input_error !== '',
    }" class="!zf-w-full zf-rounded !zf-mb-4 !zf-px-4 !zf-bg-gray-100 !zf-py-2 !zf-h-12">
      <option v-for="option in options" :value="option.value">
        {{ option.title }}
      </option>
    </select>
    <p class="!zf-text-red-700 !zf-mb-3 !zf-text-sm zf-p-0" v-if="subject_input_error !== ''">
      {{ subject_input_error }}
    </p>
    <template v-if="preChatSurveyResponses.subject === salesOption">
      <input type="text" v-model.trim="preChatSurveyResponses.name" placeholder="Name*" v-on:keypress="validateName"
        @keyup.prevent="name_input_error = ''" :class="{
          '!zf-border !zf-border-red-700 zf-mb-0': name_input_error !== '',
        }" class="!zf-w-full zf-rounded !zf-mb-4 !zf-px-4 !zf-bg-gray-100 !zf-py-2 !zf-h-12" />
      <p class="!zf-text-red-700 !zf-mb-3 !zf-text-sm zf-p-0" v-if="name_input_error !== ''">
        {{ name_input_error }}
      </p>
      <input type="text" v-model.trim="preChatSurveyResponses.email" @keyup.prevent="email_input_error = ''" :class="{
        'zf-border zf-border-red-700 zf-mb-0': email_input_error !== '',
      }" placeholder="Email*" class="!zf-w-full zf-rounded !zf-bg-gray-100 !zf-px-4 !zf-py-2 !zf-mb-4 !zf-h-12" />
      <p class="!zf-text-red-700 !zf-mb-3 !zf-text-sm zf-p-0" v-if="email_input_error !== ''">
        {{ email_input_error }}
      </p>
      <input type="tel" @keyup.prevent="fillingPhone()" @keydown="phone_input_error = ''"
        v-model.trim="preChatSurveyResponses.phone" :class="{
          '!zf-border !zf-border-red-700 zf-mb-0': phone_input_error !== '',
        }" placeholder="Phone*" class="!zf-w-full zf-rounded !zf-bg-gray-100 !zf-px-4 !zf-py-2 !zf-mb-4 !zf-h-12" />
      <p class="!zf-text-red-700 !zf-mb-3 !zf-text-sm zf-p-0" v-if="phone_input_error !== ''">
        {{ phone_input_error }}
      </p>
      <input type="text" v-model.trim="preChatSurveyResponses.companyName"
        @keyup.prevent="company_name_input_error = ''" :class="{
          'zf-border zf-border-red-700 zf-mb-0':
            company_name_input_error !== '',
        }" placeholder="Company Name*"
        class="!zf-w-full zf-rounded !zf-bg-gray-100 !zf-px-4 !zf-py-2 !zf-mb-4 !zf-h-12" />
      <p class="!zf-text-red-700 !zf-mb-3 !zf-text-sm zf-p-0" v-if="company_name_input_error !== ''">
        {{ company_name_input_error }}
      </p>
    </template>
    <input type="submit" value="START CHAT" :disabled="currentQueue.isUnAvailable" :title="currentQueue.message"
      v-if="!isLoading && preChatSurveyResponses.subject === salesOption" :class="{
        'zf-opacity-50 zf-cursor-not-allowed': currentQueue.isUnAvailable,
      }"
      class="!zf-px-8 !zf-py-2 zf-rounded-full !zf-bg-ziply-blue hover:zf-bg-ziply-blue-dark !zf-text-gray-100 zf-cursor-pointer zf-font-bold" />
    <a href="tel:1-888-688-0016" v-if="!isLoading && preChatSurveyResponses.subject !== salesOption"
      class="!zf-px-8 !zf-py-2 zf-rounded-full !zf-bg-ziply-blue hover:zf-bg-ziply-blue-dark !zf-text-gray-100 zf-cursor-pointer zf-font-bold">CALL
      1-888-688-0016</a>
  </form>
</template>
