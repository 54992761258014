import { toRaw } from "vue";

import { EVENTS, EXCEPTION_MESSAGE, MESSAGES } from "../constants";
import { postChatExceptionsToKafkaTopic } from "./kafkaLogs";

export const handleFileDownlaod = async (
  convId,
  chatSDK,
  fileMetadata,
  channelOptions,
  type,
  preChatSurveyResponses
) => {
  chatSDK
    .downloadFileAttachment(toRaw(fileMetadata))
    .then((blob) => {
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = fileMetadata.name;
      document.body.appendChild(link);
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
      document.body.removeChild(link);
    })
    .catch(async (e) => {
      try {
        await postChatExceptionsToKafkaTopic(
          EVENTS.ERROR,
          type,
          preChatSurveyResponses.value,
          channelOptions.value,
          e,
          convId.value,
          EXCEPTION_MESSAGE.DOWNLOAD_ATTACHMENT
        );
      } catch (e) {
        console.log(e);
      }
    });
};

export const handleFileUpload = async (
  evt,
  convId,
  chatSDK,
  chatMessages,
  sendingMessage,
  sendMessageError,
  channelOptions,
  type,
  preChatSurveyResponses
) => {
  const file = evt.target.files[0];
  try {
    sendingMessage.value = true;
    sendMessageError.value = "";
    const resp = await chatSDK?.uploadFileAttachment(file);
    chatMessages.value = [
      {
        id: resp.id,
        ...resp,
      },
      ...chatMessages.value,
    ];
    sendingMessage.value = false;
    evt.target.value = null;
  } catch (e) {
    console.log(e);
    sendingMessage.value = false;
    sendMessageError.value = MESSAGES.FAILED_TO_UPLOAD;
    try {
      postChatExceptionsToKafkaTopic(
        EVENTS.ERROR,
        type,
        preChatSurveyResponses.value,
        channelOptions.value,
        e,
        convId.value,
        EXCEPTION_MESSAGE.UPLOAD_ATTACHMENT
      );
    } catch (e) {
      console.log(e);
    }
  }
};
